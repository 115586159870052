import React from 'react';
import "../../styles/GlobalPages.css";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { callOutline, desktopOutline, atOutline, globeOutline, phonePortraitOutline, duplicateOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";


class TarifasTranslated extends React.Component {


  render() {

    const { t } = this.props;
    return (
      <div className="container h-100 ">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Home'} />
        </div>
        <div className="titulo-pagina">
          <span className="">{t('Tarifas.titulo',)}</span>
        </div>
        <div className="tarifa-btn-group">
          <Link
            className="text-reset text-decoration-none div-tarifas"
            to={{
              pathname: `/TarifasDetalle/`,
              state: {
                id: 1
              }
            }
            } >
            <div className="">
              <div className="tarifa-btn-descripcion">
                <span>{t('Tarifas.movil',)}</span>
              </div>

              <div className="tarifa-btn-icon">
                <IonIcon className="iconos iconostarifa" icon={phonePortraitOutline} />
              </div>
            </div>
          </Link>
          <Link
            className="text-reset text-decoration-none div-tarifas"
            to={{
              pathname: `/TarifasDetalle/`,
              state: {
                id: 4
              }
            }
            } >
            <div className="">
              <div className="tarifa-btn-descripcion">
                <span>{t('Tarifas.fibra',)}</span>
              </div>

              <div className="tarifa-btn-icon">
                <IonIcon className="iconos iconostarifa" icon={globeOutline} />
              </div>
            </div>
          </Link>
          <Link
            className="text-reset text-decoration-none div-tarifas"
            to={{
              pathname: `/TarifasDetalle/`,
              state: {
                id: 5
              }
            }
            } >
            <div className="">
              <div className="tarifa-btn-descripcion">
                <span>Packs</span>
              </div>
              <div className="tarifa-btn-icon">
                <IonIcon className="iconos iconostarifa" icon={duplicateOutline} />
              </div>
            </div>
          </Link>
          <Link
            className="text-reset text-decoration-none div-tarifas"
            to={{
              pathname: `/TarifasDetalle/`,
              state: {
                id: 6
              }
            }
            } >
            <div className="">
              <div className="tarifa-btn-descripcion">
                <span>{t('Tarifas.tv',)}</span>
              </div>
              <div className="tarifa-btn-icon">
                <IonIcon className="iconos" icon={desktopOutline} />
              </div>
            </div>
          </Link>
        </div>
      </div >
    )

  }
}
const Tarifas = withTranslation('common')(TarifasTranslated)
export default Tarifas;